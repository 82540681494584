import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import mainTheme from 'themes/main';
import AppContent from './components/AppContent/AppContent';

const App = () => (
  <ThemeProvider theme={mainTheme}>
    <Router>
      <AppContent />
    </Router>
  </ThemeProvider>
);

export default App;
