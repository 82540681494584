import React from 'react';
import { Route, Switch, useLocation, } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ROUTES } from './constants';

const useStyles = makeStyles(({ typography }: Theme) => ({
  root: {
    height: '100%',
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    position: 'relative',
  },
  animations: {
    height: '100%',
    position: 'absolute',
    width: '100%',

    '&.enter': {
      opacity: 0,
      transition: 'opacity 350ms ease-in',
      zIndex: 1,
    },
    '&.enter-active': {
      opacity: 1,
    },
  },
}));

export const AppContent = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <main className={classes.root}>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} timeout={400}>
          <div className={classes.animations}>
            <Switch location={location}>
              {ROUTES.map(({ path, ...rest }) => (
                <Route key={String(path)} path={path} {...rest} />
              ))}
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
};

export default AppContent;
