import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' { }

const fontFamily = ["'Poppins'", 'Roboto', 'Arial', 'sans-serif'].join(',');

// Possible theme: https://coolors.co/ed6a5a-f4f1bb-9bc1bc-5ca4a9-e6ebe0
export default createMuiTheme({
  palette: {
    primary: {
      light: '#7095f2',
      main: '#323343',
      dark: '#3867d6',
    },
    secondary: {
      light: '#47e696',
      main: '#ed6a5a',
      dark: '#20bf6b',
    },
    success: {
      light: '#4be699',
      main: '#26de81',
      dark: '#20bf6b',
    },
    error: {
      light: '#fd6e74',
      main: '#fc5c65',
      dark: '#eb3b5a',
    },
  },
  typography: {
    fontFamily,
    subtitle1: {
      fontFamily,
      fontSize: '1rem',
      letterSpacing: '0.00714em', // Same as subtitle2
    },
    subtitle2: {
      fontFamily,
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
});
