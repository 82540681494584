import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing }: Theme) => (
  createStyles({
    root: {
      color: palette.primary.main,
      padding: spacing(4, 0),
      width: '100%',
    },
    themeDark: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
  })
));

interface PageSectionProps {
  className?: string;
  children: ReactNode;
  theme?: 'light' | 'dark';
}

export const PageSection = (props: PageSectionProps) => {
  const classes = useStyles();
  const { children, className, theme = 'light' } = props;

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.themeDark]: theme === 'dark' },
        className,
      )}
    >
      {children}
    </div >
  );
};

export default PageSection;
