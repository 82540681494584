import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from 'components/Container/Container';
import PageSection from 'components/PageSection/PageSection';
import Subtitle from 'components/Subtitle/Subtitle';
import Title from 'components/Title/Title';
import BackgroundPattern from 'images/backgrounds/sayagata-400px.png';
import BouncingNameBall from './components/BouncingNameBall/BouncingNameBall';

const useStyles = makeStyles(({ palette, spacing, typography }: Theme) => (
  createStyles({
    root: {
      background: `url(${BackgroundPattern})`,
      height: '100%',
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(0, 4),
    },
    info: {},
    ballContainer: {
      height: 40,
    },
    ball: {
      marginBottom: spacing(3),
    },
    comingSoon: {
      ...typography.h6,
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      fontWeight: typography.fontWeightBold,
      marginTop: spacing(1),
      padding: spacing(2, 4),
      textAlign: 'center',
      textShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      transition: 'all 0.15s',
      width: '100%',

      '&:hover': {
        backgroundColor: '#00b4d8',
      },
    },
    footer: {
      bottom: 0,
      color: palette.primary.main,
      marginBottom: spacing(2),
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
    },
  })
));

interface HomeProps {
  className?: string;
}

export const Home = (props: HomeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageSection>
        <Container className={classes.container} fixed fullHeight>
          <div className={classes.info}>
            <div className={classes.ballContainer}>
              <BouncingNameBall className={classes.ball} />
            </div>
            <Title>Jeff Zhan</Title>
            <Subtitle bold>Software Engineer, Full Stack</Subtitle>
            <Subtitle>San Francisco, CA</Subtitle>
            <div className={classes.comingSoon}>
              Coming Soon
            </div>
          </div>
          <div className={classes.footer}>
            2020
          </div>
        </Container>
      </PageSection>
    </div>
  );
};

export default Home;
