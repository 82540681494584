import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) => (
  createStyles({
    root: {
      backgroundColor: '#00b4d8',
      borderRadius: '50%',
      animation: '$bounce 0.5s',
      animationDirection: 'alternate',
      animationTimingFunction: 'cubic-bezier(0.5, 0.05, 1, 0.5)',
      animationIterationCount: 'infinite',
      height: spacing(2),
      left: 16,
      position: 'relative',
      width: spacing(2),
    },
    '@keyframes bounce': {
      '0%': {
        transform: 'translateY(0)',
      },
      '80%': {
        height: spacing(2),
      },
      '95%': {
        transform: 'translateY(30px)',
        height: spacing(1.5),
      },
      '100%': {
        transform: 'translateY(30px)',
        height: spacing(1.5),
      },
    },
  })
));

interface BouncingNameBallProps {
  className?: string;
}

export const BouncingNameBall = (props: BouncingNameBallProps) => {
  const classes = useStyles();
  const { className } = props;

  return <div className={classNames(classes.root, className)} />;
};

export default BouncingNameBall;
