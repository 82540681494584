
import { RouteProps } from 'react-router-dom';
import { PATH } from 'constants/routes';
import ComingSoon from 'scenes/ComingSoon/ComingSoon';

export const ROUTES: ReadonlyArray<RouteProps> = [
  {
    exact: true,
    path: PATH.ROOT,
    component: ComingSoon,
  },
];
