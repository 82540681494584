import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography }: Theme) => (
  createStyles({
    root: {
      ...typography.subtitle1,
    },
    bold: {
      ...typography.subtitle2,
    },
  })
));

interface SubtitleProps {
  className?: string;
  children: ReactNode;
  bold?: boolean;
}

export const Subtitle = (props: SubtitleProps) => {
  const classes = useStyles();
  const { children, className, bold = false } = props;

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.bold]: bold },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Subtitle;
