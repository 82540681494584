import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ typography }: Theme) => (
  createStyles({
    root: {
      ...typography.h2,
      fontWeight: 700,
      letterSpacing: 1.1
    },
  })
));

interface TitleProps {
  className?: string;
  children: ReactNode;
}

export const Title = (props: TitleProps) => {
  const classes = useStyles();
  const { children, className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      {children}
    </div>
  );
};

export default Title;
