import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => (
  createStyles({
    root: {
      margin: '0 auto',
      maxWidth: 1200,
      width: '100%',
    },
    fixed: {
      maxWidth: 'initial',
      position: 'fixed',
      top: 0,
    },
    fullHeight: {
      height: '100%',
    },
  })
));

interface ContainerProps {
  className?: string;
  children: ReactNode;
  fixed?: boolean;
  fullHeight?: boolean;
}

export const Container = (props: ContainerProps) => {
  const classes = useStyles();
  const {
    children,
    className,
    fixed,
    fullHeight,
  } = props;

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.fixed]: fixed,
          [classes.fullHeight]: fullHeight,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Container;
